import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import { Recogito } from "@recogito/recogito-js"

import "@recogito/recogito-js/dist/recogito.min.css"

export default class extends Controller {
  initialize() {
    const rec = new Recogito({ content: this.element })
    rec.loadAnnotations(`/lessons/${this.lessonId}/annotations.json`)

    rec.on("createAnnotation", (annotation) => this._upsertAnnotation(annotation))
    rec.on("updateAnnotation", (annotation, _) => this._upsertAnnotation(annotation))
    rec.on("deleteAnnotation", (annotation) => this._deleteAnnotation(annotation))
  }

  get lessonId() {
    return this.element.dataset.lessonId
  }

  _upsertAnnotation(annotation) {
    fetch(`/lessons/${this.lessonId}/annotations`, {
      method: "post",
      body: JSON.stringify({ annotation }),
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      credentials: "same-origin"
    })
  }

  _deleteAnnotation(annotation) {
    const id = annotation.id.split("#")[1]
    fetch(`/lessons/${this.lessonId}/annotations/${id}`, {
      method: "delete",
      headers: {
        "Accept": "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken()
      },
      credentials: "same-origin"
    })
  }
}
